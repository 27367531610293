import React, {useEffect, useState} from 'react'
import {withI18n} from "react-i18next";
import "./Template.scss"
import TemplatesService from 'services/domain/TemplatesService';
import ModificationObjectModal from './modify-template/ModificationObjectModal';
import ModificationTagsModal from './modify-template/ModificationTagsModal';
import {contentTemplateLanguage} from "models/Template";

function TemplatePage({ t }): React.Node {

  return (
    <React.Fragment>
      
    </React.Fragment>
  )
}

export default withI18n()(TemplatePage);
