/**
 * ErrorHandler
 * @module Services/error/ErrorHandler
 * @description services for ErrorHandler
 */

const self = {};


/**
 * Transforms a Yup validation error to a more specific error prefixed with E_ and post-fixed with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeOneValidationError
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} field The field of the error
 * @param   {string} error the error
 * @returns {string}  a more specific error
 */
const normalizeOneValidationError = (field, error) => {
  if (error.indexOf("E_") === 0) {
    return error;
  }
  return 'E_' + error.toUpperCase() + '_' + field.toUpperCase();
};

/**
 * Transforms a set Yup validation errors to a more specific errors prefixed with E_ and post-fixed
 * with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeValidationErrors
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} errors The set of errors
 * @returns {array}  a set of more specific errors
 */
self.normalizeValidationErrors = (errors) => {
  if (!errors) {
    return;
  }
  for (let field in errors) {
    errors[field] = normalizeOneValidationError(field, errors[field]);
  }
  return {errors: errors};
};

/**
 * Handles a set of errors and sets them to the errorActions field
 * @author Sameh Bellez
 * @alias handleError
 * @memberof module:Services/error/ErrorHandler
 * @param   {arrays} errors The errors
 * @returns {void}
 */
self.handleError = (toastManager, t, error) => {
  if (!error || !error.data) {
    return;
  }

  if(!error.data.errors || !error.data.errors.length) {
    if(!error.data.code) {
      return;
    }
    error.data.errors = [{code: error.data.code, message: (error.data.message ? error.data.message : "No Message")}];
  }

  error.data.errors.forEach(err => {
    toastManager(t(`errors.${err.code}`), {
      appearance: 'error', autoDismiss: true
    });
  });
};

export default self;
