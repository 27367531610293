// @flow
import EmptyContent from "components/common/empty-content/EmptyContent";
import * as React from "react";
import { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import ApplicationsService from "services/domain/ApplicationsService";
import UsersService from "services/domain/UsersService";
import { Loader, Page } from "tabler-react";
import SiteWrapper from "../../../SiteWrapper";
import UsersList from "./components/users-list/UsersList";



function UsersPage({ t }): React.Node {
  const [users, setUsers] = useState(null);
  const [applications, setApplications] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadData = () => {
    setLoading(true);

    UsersService.getUsersScope()
      .then(data => {
        setUsers(data)
      })
      .finally(() => setLoading(false));

    ApplicationsService.getApplications()
      .then(data => {
        setApplications(data)
      })
  };

  useEffect(() => {
    document.title = t('navbar.applications');
    loadData()
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title={t('navbar.users')} />

        {loading && <Loader />}

        {(!loading && users && users.length > 0) && (
          <UsersList users={users} applications={applications} />
        )}

        {(!loading && (!users || !users.length)) && (
          <EmptyContent text={t('globals.no-results')} />
        )}

      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(UsersPage);
