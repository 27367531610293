// @flow
import { Formik } from "formik";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Button, Form, Grid } from "tabler-react";
import DropZone from "../../common/dropzone/DropZone";


const ApplicationUploadModal = (props): React.Node => {

  const [filePath, setFilePath] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const componentConfig = {
    iconFiletypes: ["file"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.FILE.UPLOAD_FILE.path
  };

  const uploadFilesConfig = {
    eventHandlers: {
      success: (file, response) => {
        if (response && response.data && response.data.file && response.data.file.url) {
          setFilePath(response.data.file.url);
        }
      }
    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('upload.import-files'),
      dictRemoveFile: props.t('upload.delete-file'),
      addRemoveLinks: true,
      acceptedFiles: "application/json",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        // formData.append("file", file);
      }
    }
  };


  const onSubmit = (values, actions) => {
    setLoading(true);
    let body = {
      upload_file_url: filePath
    };
    props.onSubmitCallback(body).then((data) => {
      props.onHide && props.onHide();
      props.afterCreation && props.afterCreation(data);
    })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col xs="12" sm="12">
                <DropZone
                  componentConfig={componentConfig}
                  dropZoneConfig={uploadFilesConfig.djsConfig}
                  eventHandlers={uploadFilesConfig.eventHandlers}
                />
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide}
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading} onClick={onSubmit}
              loading={loading}>{props.t('globals.upload')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('applications.import-application-modal-title')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
    />
  </Modal>);
};

export default withI18n()(ApplicationUploadModal);
