import Keycloak from "keycloak-js";
import cookies from 'js-cookie';

import AuthService from "./AuthService";

const LS_TAG_USER_SCOPE = "user_scope";
const REFRESH_TOKEN_INTERVAL = 5 * 60 * 1000; // MILLISECOND

const _kc = new Keycloak("/keycloak.json");

let self = {};

self.kc = _kc;

self.current_user = {};

self.signinClient = () => {
  return new Promise(async (resolve, reject) => {
    _kc.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    }).then((authenticated) => {
      console.log("_kc", _kc);
      if(authenticated) {
        console.log("_kc.tokenParsed", _kc.tokenParsed);
        localStorage.setItem(LS_TAG_USER_SCOPE, _kc.tokenParsed.realm_access.roles);

        // Check if the user is authenticated. If not, verify token from server (and create user)
        // if(!AuthService.isAuthenticated()) {
          AuthService.validateTokenFromServer(_kc).then(data => {
            localStorage.setItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG, _kc.token);
            resolve(_kc.tokenParsed);
          })
          .catch(error => {
            console.log(error);
            self.logout();
          });
        // } else {
        //   resolve(_kc.tokenParsed);
        // }

        // Load user profile
        _kc.loadUserProfile().success(user => {
          self.current_user = user;
        })

        // Refresh token
        setInterval(function() {
          _kc.updateToken(-1).then(function(refreshed) {
            if(refreshed) {
              localStorage.setItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG, _kc.token);
            }
          }).catch(function() {
            console.log('Failed to refresh token');
            self.logout();
            _kc.login();
          }); 
        }, REFRESH_TOKEN_INTERVAL)
        
        _kc.onTokenExpired = ()=>{
          console.log('expired '+new Date());
          _kc.updateToken(50).success((refreshed)=>{
            if (refreshed){
                localStorage.setItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG, _kc.token);
                  console.log('refreshed '+new Date());
              }else {
                  console.log('not refreshed '+new Date());
              }
          }).error(() => {
               console.error('Failed to refresh token '+new Date());
          });
          }

      
      } else {
        self.logout();
        _kc.login();
        reject(false);
      }
    });
  });
};


self.logout = () => {
  const cookieOptions = {
    path: '/', domain: process.env.REACT_APP_KYC_DOMAIN, expires: 365
  };
  localStorage.removeItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
  cookies.remove(process.env.REACT_APP_BO_KYC_ACCESS_TOKEN_TAG, cookieOptions);
  _kc.logout();
};

self.hasRole = (role) => {
  return self.kc.hasResourceRole(role, self.kc.clientId);
};

export default self;