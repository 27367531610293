// @flow

export const contentTemplateLanguage = [{
  value: 0,
  label: "FR",
  logo_src: "/assets/images/fr.png"
}, {
  value: 1,
  label: "EN",
  logo_src: "/assets/images/en.png"
}, {
  value: 2,
  label: "IT",
  logo_src: "/assets/images/it.png"
}, {
  value: 3,
  label: "ES",
  logo_src: "/assets/images/es.png"
}, {
  value: 4,
  label: "DE",
  logo_src: "/assets/images/de.png"
}];

export const templateFileTypes = [{
  value: "PDF",
  label: "PDF",
  content_type: "application/pdf"
}, {
  value: "DOCX",
  label: "DOCX",
  content_type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}, {
  value: "PPTX",
  label: "PPTX",
  content_type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
}];


export const isAvailable = (template) => {
  if (template.enabled == false) {
    return false;
  }
  return true;
}

export const getLanguageByValue = (value) => {
  for (let i = 0; i < contentTemplateLanguage.length; i++) {
    if (value === contentTemplateLanguage[i].value) {
      return contentTemplateLanguage[i];
    }
  }
  return null;
}

export function getDefaultContentLanguage(template) {
  if (template && template.template_content && template.template_content.length > 0) {
    for (let key in template.template_content) {
      if (template.template_content[key].is_default) {
        return template.template_content[key];
      }
    }
  }
  return null;
};
