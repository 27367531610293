import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import "dropzone/dist/dropzone.css";
import "react-dropzone-component/styles/filepicker.css";

function DropZone({componentConfig, dropZoneConfig, eventHandlers}) {

    return (
        <div className="dropzone-wrapper">
            <DropzoneComponent
                config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={dropZoneConfig}
            />
        </div>
    )
}

export default DropZone;