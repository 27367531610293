/**
 * UsersService
 * @module Services/http/TemplatesService
 * @description Loads users data from the server
 */

import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";

const self = {};

self.getAppTemplates = (id: string, params): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.GET_APP_TEMPLATES, { id: id, ...params })
    .then(data => data);
};

self.createTemplate = (applicationId: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.CREATE_TEMPLATE, { applicationId }, data)
    .then(data => data);
};

self.createContentTemplate = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.CREATE_CONTENT_TEMPLATE, { id }, data)
    .then(data => data);
};

self.getContentTemplate = (template_id: string, content_id: string): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.GET_CONTENT_TEMPLATE, { template_id, content_id })
    .then(data => data);
};

self.saveContentTemplate = (template_id: string, content_id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.SAVE_CONTENT_TEMPLATE, { template_id, content_id }, data)
    .then(data => data);
};

self.exportTemplate = (id: string): Promise<PromoCode> => {
  return HttpBackendClient.client({
    url: HttpBackendClient.formatFullUrl(LinksProvider.API.TEMPLATE.EXPORT_TEMPLATE.path, { id }),
    method: LinksProvider.API.TEMPLATE.EXPORT_TEMPLATE.method,
    responseType: 'blob',
    headers: {
      'Keep-Original-Response': true
    }
  });
};

self.update = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.UPDATE_TEMPLATE, { id }, data)
    .then(data => data);
};

self.updateContentTemplate = (template_id: string, content_id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.UPDATE_CONTENT_TEMPLATE, { template_id, content_id }, data)
    .then(data => data);
};

self.downloadContentTemplate = (template_id: string, content_id: string): Promise<PromoCode> => {
  return HttpBackendClient.client({
    url: HttpBackendClient.formatFullUrl(LinksProvider.API.TEMPLATE.DOWNLOAD_CONTENT_TEMPLATE.path, { template_id, content_id }),
    method: LinksProvider.API.TEMPLATE.DOWNLOAD_CONTENT_TEMPLATE.method,
    responseType: 'blob',
    headers: {
      'Keep-Original-Response': true
    }
  });
};

self.generateTemplate = (data, headers): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.FILE.GENERATE_FILE, null, data, headers)
    .then(data => data);
};

self.getContentTemplateSignedUrl = (template_id: string, content_id: string): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.TEMPLATE.GET_CONTENT_TEMPLATE_SIGNED_URL, { template_id, content_id })
    .then(data => data);
};

export default self;
