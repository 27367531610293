import DateService from "@mkz-service/date";
import React from "react";
import { withI18n } from 'react-i18next';
import { Link } from "react-router-dom";
import { Card, Table } from "tabler-react";
import LinksProvider from "../../../../../services/http/LinksProvider";


const ApplicationsList = ({ userApplications, t }) => {
  const headerItems = [{ content: "Nom" },
  { content: "Active" },
  { content: "Date de création" }];


  const itemRow = (userApplications) => {
    return {
      key: userApplications.app_id, item: [{
        content: (
          <Link
            to={LinksProvider.formatUrl(LinksProvider.ROUTES.APPLICATION, { id: userApplications.app_id })}
          >
            {userApplications.app_name}
          </Link>
        )
      },
      { content: (<span>{(userApplications.app_enabled) ? t('globals.yes') : t('globals.no')}</span>) },
      { content: (<span>{DateService.formatDate(userApplications.app_creation_date)}</span>) }

      ]
    };
  };

  return (<React.Fragment>
    <Card>
      <Card.Header>
        <Card.Title>{t('applications.applications-found')}</Card.Title>
      </Card.Header>
      <Card.Body>
        {(userApplications) && (

          <Table responsive
            className="card-table"
            headerItems={headerItems}
            bodyItems={userApplications.map(itemRow)} />
        )}
      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(ApplicationsList);
