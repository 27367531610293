// @flow
import type { NavItem } from "models/NavBar";
import { NavLink, withRouter } from "react-router-dom";
import AuthService from 'services/domain/AuthService';
import KeycloakService from 'services/domain/KeycloakService';
import LinksProvider from "../http/LinksProvider";

const self = {};

self.getNavBarItems = (t): Array<NavItem> => {
  const navItems = [{
    value: t('navbar.home'),
    to: LinksProvider.ROUTES.HOME,
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true
  }, {
    value: t('navbar.applications'),
    to: LinksProvider.ROUTES.APPLICATIONS,
    icon: "file",
    LinkComponent: withRouter(NavLink)
  }, {
    value: t('navbar.users'),
    to: LinksProvider.ROUTES.USERS,
    icon: "user",
    LinkComponent: withRouter(NavLink),
    authorization: AuthService.AUTHORIZATIONS.EMAILING_ADMIN_USER
  }];

  const principal = AuthService.getPrincipal();

  if (!principal) {
    return [navItems[0]];
  }

  return navItems.filter(item => !item.authorization
    || KeycloakService.hasRole(item.authorization));
};

export default self;
