// @flow
import * as React from "react";
import styles from './EmptyContent.module.scss'
import {Icon} from "tabler-react";

function EmptyContent(props): React.Node {
  return (<React.Fragment>
    <div className={styles.container}>
      <Icon prefix="fa" name="file-text" className={styles.icon}/>
      <h3>{props.text}</h3>
    </div>
  </React.Fragment>);
}

export default EmptyContent;
