import * as React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import Error404 from "./pages/404/Error404Page";

import {ToastProvider} from 'react-toast-notifications';

import "tabler-react/dist/Tabler.css";
import "./styles/App.scss";

import LinksProvider from 'services/http/LinksProvider';
import AuthService from 'services/domain/AuthService';

import ProtectedRoute from "components/auth/ProtectedRoute";
import {withNamespaces} from 'react-i18next';
import ApplicationsPage from "pages/applications/application-page/ApplicationsPage";
import ApplicationDetailsPage from "pages/applications/application-details-page/ApplicationDetailsPage";
import TemplatePage from "pages/template/TemplatePage";
import UsersPage from "pages/users/users-page/UsersPage";

function App(props): React.Node {

  return (<ToastProvider>
    <React.StrictMode>
      <Router>
        <Switch>

          <ProtectedRoute exact path={LinksProvider.ROUTES.HOME} component={HomePage}/>



          <ProtectedRoute exact path={LinksProvider.ROUTES.USERS}
                          component={UsersPage}
                          authorization={AuthService.AUTHORIZATIONS.ADMIN_USER}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.APPLICATIONS}
                          component={ApplicationsPage}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.APPLICATION}
                          component={ApplicationDetailsPage}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.TEMPLATE}
                          component={TemplatePage}/>
          

          <Route component={Error404}/>
        </Switch>
      </Router>
    </React.StrictMode>
  </ToastProvider>);
}

export default withNamespaces()(App);
