import React, {useEffect, useState} from 'react';
import {Badge, Card, Table, Icon, Loader} from "tabler-react";
import PaginationService from 'services/http/PaginationService';

const MyColumn = ({column, sortableObject, onLoadingStart, onLoadingEnd}): React.Node => {


  const [link, setLink] = useState(null);

  useEffect(() => {
    if(sortableObject && sortableObject.links && column.key && sortableObject.links[column.key]) {
      setLink(sortableObject.links[column.key])
    }
  }, [column]);
  
  const sortTable = () => {
    if(link) {
      if(onLoadingStart) {
        onLoadingStart();
      }
      PaginationService.get(link).then((data) => {
        if(onLoadingEnd) {
          onLoadingEnd(data);
        }
      })
      .finally(() => {

      });
    }
  }

  return (
    <Table.ColHeader >
     <span onClick={sortTable}>
       <React.Fragment>
        {column.content}
        {(link && link.key === column.key) && ( 
          <React.Fragment>
            &nbsp;
            {(sortableObject.key === column.key) && (sortableObject.way === "asc") && (
              <Icon prefix="fa" name="sort-down"/>
            )}
            {(sortableObject.key === column.key) && (sortableObject.way === "desc")  && (
              <Icon prefix="fa" name="sort-up"/>
            )}
            {(sortableObject.key !== column.key) && ( // == 0
              <Icon prefix="fa" name="sort"/>
            )}
            </React.Fragment>
            )}
        </React.Fragment>
      </span>
    </Table.ColHeader>
  )
}

export default MyColumn;
