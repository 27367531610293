import DateService from "@mkz-service/date";
import Editable from "@mkz-wc/react-editable";
import ApplicationUploadModal from "components/modals/application-upload-modal/ApplicationUploadModal";
import ConfirmModal from "components/modals/confirm-modal/ConfirmModal";
import { applicationEnabledValues } from "models/Application";
import React, { useEffect, useState } from "react";
import { withI18n } from 'react-i18next';
import { useToasts } from "react-toast-notifications";
import ApplicationsService from "services/domain/ApplicationsService";
import AuthService from "services/domain/AuthService";
import KeycloakService from "services/domain/KeycloakService";
import ErrorHandler from "services/error/ErrorHandler";
import { Download, Trash, Upload } from 'tabler-icons-react';
import { Card, Dimmer, Grid } from "tabler-react";



const ApplicationsList = ({ application, t }) => {

  const { addToast } = useToasts();

  const [model, setModel] = useState(null);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [uploadAppModalShown, setUploadAppModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creationModalShown, setCreationModalShown] = useState(false);

  useEffect(() => {
    setModel(application);
  }, []);

  const editApplication = (field) => {
    return (val) => {

      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        model[field] = val;
        request[field] = val;
        if (field === "category" || field === "status") {
          model[field] = parseInt(val);
          request[field] = parseInt(val);
        }
      }
      updateApplication(request);
    }
  };
  const updateApplication = (request) => {
    setLoading(true);
    return ApplicationsService.update(application.id, request)
      .then(application => {
        return application;
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
      })
      .finally(() => setLoading(false));
  };

  const onSubmitCallback = (body) => {
    return ApplicationsService.importMyApp(application.id, body)
      .then();
  };

  const deleteApplication = () => {
    setLoading(true);
    let request = {
      deletion_date: new Date()
    }
    return ApplicationsService.update(application.id, request)
      .then(application => {
        return application;
        // TODO redirection to /applications
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
      })
      .finally(() => {
        setLoading(false);
        setDeleteModalShown(false);
      });
  };


  const exportApplication = (application_id) => {
    ApplicationsService.export(application_id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `application-${application_id}.json`);
      document.body.appendChild(link);
      link.click();
    })
  };

  return (<React.Fragment>
    <ConfirmModal
      confirmationText={t('globals.ask-for-confirmation')}
      onConfirm={() => deleteApplication(application.id)}
      show={deleteModalShown}
      onHide={() => setDeleteModalShown(false)} />
    <ApplicationUploadModal afterCreation={(application) => {
      window.location.reload()
    }} show={uploadAppModalShown} onHide={() => setUploadAppModalShown(false)} onSubmitCallback={onSubmitCallback} />
    <Grid.Row cards deck>
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>{t('applications.refentiel-title')}</Card.Title>
            <Card.Options>
              <Upload className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => setUploadAppModalShown(true)} />
              <Download className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => exportApplication(application.id)} />
              <Trash className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => setDeleteModalShown(true)} />
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer active={loading} loader>
              <Grid.Row>
                <Grid.Col width={6}>
                  <h5>{t('globals.name')}</h5>
                  <Editable
                    initialValue={application.name}
                    undefinedText={t('globals.no-value')}
                    isValueClickable={true}
                    isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                    onSubmit={editApplication('name')}
                    type="textfield" />
                </Grid.Col>
                <Grid.Col width={6}>
                  <h5>{t('globals.creation-date')}</h5>
                  <p>{DateService.formatDate(application.creation_date)}</p>
                </Grid.Col>
                <Grid.Col width={6}>
                  <h5>{t('applications.client-id')}</h5>
                  <p>{application.id}</p>
                </Grid.Col>
                <Grid.Col width={6}>
                  <h5>{t('applications.client-secret')}</h5>
                  <p>{application.client_secret}</p>
                </Grid.Col>
                <Grid.Col width={6}>
                  <h5>{t('globals.enabled')}</h5>
                  <Editable
                    initialValue={application.enabled}
                    undefinedText={t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editApplication('enabled')}
                    isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                    type="select" options={applicationEnabledValues.map(obj => {
                      return {
                        label: t(`globals.${obj.key}`),
                        value: obj.value
                      }
                    })} />
                </Grid.Col>
                <Grid.Col width={6}>
                  <h5>{t('globals.creation-date')}</h5>
                  <p>{DateService.formatDate(application.creation_date)}</p>
                </Grid.Col>
              </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Grid.Row>
  </React.Fragment>)
};

export default withI18n()(ApplicationsList);
