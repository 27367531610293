// @flow
import DropZone from "components/common/dropzone/DropZone";
import { Formik } from "formik";
import _ from 'lodash';
import { contentTemplateLanguage } from "models/Template";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Button, Form, Grid, Icon } from "tabler-react";


const AddContentTemplateModal = (props): React.Node => {

  const [filePath, setFilePath] = useState([]);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    djsConfig.acceptedFiles = "." + props.template.type;
    setModel(props.template);
  }, [props.template]);

  var myDropZone;

  const componentConfig = {
    iconFiletypes: ["file"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.FILE.UPLOAD_FILE.path
  };

  const djsConfig = {
    maxFilesize: 10,
    maxFiles: 1,
    dictDefaultMessage: props.t('upload.import-file'),
    dictRemoveFile: props.t('upload.delete-file'),
    dictInvalidFileType: props.t('upload.insupported-file'),
    addRemoveLinks: true,
    // acceptedFiles: ".doc,.docx,.ppt,.pptx",
    maxfilesexceeded: function (file) {
      this.removeFile(file);
    },
    sending: function (file, xhr, formData) {
      // formData.append("file", file);
    }
  };

  function getDefaultContent() {
    if (props.template && props.template.template_content) {
      for (let key in props.template.template_content) {
        if (props.template.template_content[key].is_default) {
          return props.template.template_content[key];
        }
      }
    }
  };

  const onSubmit = (values, actions) => {
    setLoading(true);
    TemplatesService.createContentTemplate(props.template.id, values)
      .then(template => {

        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(template);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        {(model) && (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={props.t('globals.language')}>
                    <Form.SelectGroup pills >
                      {contentTemplateLanguage.map((language) => {
                        if (!_.find(props.template.template_content, { language: language.value })) {
                          return (
                            <Form.SelectGroupItem
                              label={language.label}
                              name="language"
                              value={language.value}
                              onChange={(event) => {
                                const value = language.value;
                                setFieldValue('language', event.target.value);
                              }}
                              checked={(parseInt(values.language) === language.value)}
                            />
                          )
                        }
                      })}
                    </Form.SelectGroup>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={props.t('files.file')}>
                    <DropZone
                      componentConfig={componentConfig}
                      dropZoneConfig={djsConfig}
                      eventHandlers={{
                        init: (dropzone) => {
                          myDropZone = dropzone;
                        },
                        success: (file, response) => {
                          console.log("response: ", response);
                          if (response && response.data && response.data.file && response.data.file.url) {
                            setFilePath(response.data.file.url);
                            setFieldValue('file_path', response.data.file.url);
                          }
                        },
                        error: (file, error) => {
                          // TODO NORMALIZE ERROR FROM BE
                          const errors = {
                            data: {
                              errors: [{
                                code: "UNSUPPORTED_FILE",
                                message: "No Msg"
                              }]
                            }
                          }
                          ErrorHandler.handleError(addToast, props.t, errors)
                        }
                      }} />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <span>
                <Icon prefix="fe" name="alert-circle text-primary" />
                &nbsp;Veuillez vous référer à la documentation de &nbsp;
                <a target="_blank" href="https://docxtemplater.com/demo/#simple">
                  docxtemplater sur le lien suivant
                </a>
              </span>

            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={props.onHide} closeButton
                color="warning">{props.t('modals.close')}</Button>
              <Button type="submit" color="primary" disabled={loading}
                loading={loading}>{props.t('globals.create')}</Button>
            </Modal.Footer>
          </Form>
        )}
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.plus-content-template-modal-title')}</Modal.Title>
    </Modal.Header>

    <Formik
      onSubmit={onSubmit}
      render={render}
      initialValues={{
        language: 0
      }}
    />
  </Modal>);
};

export default withI18n()(AddContentTemplateModal);
