/**
 * PaginationService
 * @module Services/http/PaginationService
 * @description Loads users data from the server
 */


import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";

const self = {};

/**
 * Searches for a user with a specific query
 * @author Mohamed CHEKIR
 * @alias search
 * @memberof module:Services/http/PaginationService
 * @returns {Promise<User>}  A promise containing the search result
 */
self.get = (link: string): Promise<User> => {
  return HttpBackendClient.client
    .get(link.url)
    .then(data => data);
};


/**
 * Searches for a user with a specific query
 * @author Mohamed CHEKIR
 * @alias search
 * @memberof module:Services/http/PaginationService
 * @returns {Promise<User>}  A promise containing the search result
 */
self.sort = (link: string, sort: string): Promise<User> => {
  return HttpBackendClient.client
    .get(LinksProvider.formatUrl(link.url) + '&sort=' + sort)
    .then(data => data);
};

export default self;
