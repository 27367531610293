/**
 * HttpService
 * @module Services/http/HttpService
 * @name HttpService
 * @description Returns a HttpService object after configuring an axios instance
 */

import HttpService from '@mkz-service/http';

export const HttpBackendClient = new HttpService({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 60000,
  localStorageTag: process.env.REACT_APP_BO_ACCESS_TOKEN_TAG
});

export default HttpService;
