import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PaginationService from 'services/http/PaginationService';

// pagination.count : NB element (all)
// pagination.limit : NB element per page
function TablePagination({ pagination, onLoadingStart, onLoadingEnd }): React.Node {

  const handleChangePage = (e, link) => {
    if (onLoadingStart) {
      console.log("onLoadingStart")
      onLoadingStart();
    }
    PaginationService.get(link).then((data) => {
      if (onLoadingEnd) {
        console.log("onLoadingEnd")
        onLoadingEnd(data);
      }
    });
  }

  return (
    <span class="pull-right">
      <Pagination aria-label="table navigation pull-right">
        <PaginationItem disabled={pagination.links.FIRST === undefined}>
          <PaginationLink onClick={e => handleChangePage(e, pagination.links.FIRST)} first /></PaginationItem>
        <PaginationItem disabled={pagination.links.PREVIOUS === undefined}>
          <PaginationLink previous onClick={e => handleChangePage(e, pagination.links.PREVIOUS)} />
        </PaginationItem>

        {(pagination.links.PREVIOUS) && (
          <PaginationItem>
            <PaginationLink onClick={e => handleChangePage(e, pagination.links.PREVIOUS)} >
              {(parseInt(pagination.currentPage) - 1)}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem active={pagination.currentPage}>
          <PaginationLink >
            {pagination.currentPage}
          </PaginationLink>
        </PaginationItem>

        {(pagination.links.NEXT) && (
          <PaginationItem>
            <PaginationLink onClick={e => handleChangePage(e, pagination.links.NEXT)} >
              {(parseInt(pagination.currentPage) + 1)}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem disabled={pagination.links.NEXT === undefined}>
          <PaginationLink next onClick={e => handleChangePage(e, pagination.links.NEXT)} />
        </PaginationItem>
        <PaginationItem disabled={pagination.links.LAST === undefined}>
          <PaginationLink last onClick={e => handleChangePage(e, pagination.links.LAST)} />
        </PaginationItem>
      </Pagination>
    </span>
  );
}

export default TablePagination;
