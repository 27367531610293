// @flow
import Editable from '@mkz-wc/react-editable';
import { getLanguageByValue } from "models/Template";
import React, { useEffect, useState } from 'react';
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import AuthService from "services/domain/AuthService";
import KeycloakService from "services/domain/KeycloakService";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import { Form, Grid } from "tabler-react";

const ViewContentTemplateModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();


  useEffect(() => {
    props.template.default_language = getDefaultContentLanguage();
    setModel(props.template)
  }, [props.template]);

  function getDefaultContentLanguage() {
    if (props.template && props.template.template_content) {
      for (let key in props.template.template_content) {
        if (props.template.template_content[key].is_default) {
          return props.template.template_content[key].language;
        }
      }
    }
  };

  const editTemplate = (field) => {
    return (val) => {
      console.log("typeof here ", typeof val);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        if (field == "enabled") {
          val = (val === true || val === "true") ? true : false;
          request[field] = val;
        } else if (field == "default_language") {
          val = parseInt(val);
          request[field] = val;
        } else {
          request[field] = val;
        }
        model[field] = val;
      }
      setModel({ ...model });
      TemplatesService.update(props.template.id, request)
        .then(template => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
      // updateProduct(request);
    }
  };

  return (
    <React.Fragment>
      {(model) && (
        <React.Fragment>
          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.id')}</h5></Grid.Col>
            <Grid.Col>{model.id}</Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.name')}</h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={model.name}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                  onSubmit={editTemplate('name')}
                  type="textfield" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.description')}</h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={model.description}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                  onSubmit={editTemplate('description')}
                  type="textarea" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.enabled')}</h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={model.enabled}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                  onSubmit={editTemplate('enabled')}
                  type="select" options={[{ label: props.t('globals.yes'), value: true }, { label: props.t('globals.no'), value: false }]}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.default-language')}</h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={model.default_language}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                  onSubmit={editTemplate('default_language')}
                  type="select" options={props.template.template_content.map((template_content) => {
                    return {
                      label: getLanguageByValue(template_content.language).label,
                      value: template_content.language
                    }
                  })} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.type')}</h5></Grid.Col>
            <Grid.Col>
              {model.type}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col></Grid.Col>
          </Grid.Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};


export default withI18n()(ViewContentTemplateModal);
