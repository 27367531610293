import React, {useEffect, useState} from 'react';
import {Badge, Card, Table, Icon, Loader} from "tabler-react";
import MyColumnHeader from "./table-column/TableColumn";
import TablePagination from "components/common/table/table-pagination/TablePagination";

const MyTable = (props): React.Node => {

  return (
    // <Table {...props}>
    <React.Fragment>
      <Table responsive className="card-table">
        <Table.Header>
          {(props.headerItems) && props.headerItems.map((column, i) => {
            return (
              <MyColumnHeader 
                pagination={props.pagination} 
                column={column} 
                sortableObject={props.sortableObject} 
                onLoadingStart={props.onLoadingStart}
                onLoadingEnd={props.onLoadingEnd}
              />
            )
          })}
        </Table.Header>

        <React.Fragment>
          { React.createElement(
              Table.Body,
              null,
              props.bodyItems.map(function (row, i) {
                return React.createElement(
                  Table.Row,
                  { key: row.key },
                  row.item.map(function (col, i) {
                    return React.createElement(
                      Table.Col,
                      {
                        className: col.className,
                        alignContent: col.alignContent,
                        key: i
                      },
                      col.content
                    );
                  })
                );
              })
            )}
        </React.Fragment> 

      </Table>
      
      {(props.pagination) && (
          <TablePagination
            pagination={props.pagination}
            onLoadingStart={props.onLoadingStart}
            onLoadingEnd={props.onLoadingEnd}
            />
        )}
              </React.Fragment> 
  );
}

export default MyTable;
