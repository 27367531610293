// @flow
import EmptyContent from 'components/common/empty-content/EmptyContent';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Button, Grid, Tab, Tabs } from "tabler-react";
import EditTemplateContentComponent from "./edit-template-components/EditTemplateContentComponent";
import EditTemplateReferentielComponent from "./edit-template-components/EditTemplateReferentielComponent";

const ViewContentTemplateModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showEditFile, setShowEditFile] = useState(false);
  const [filePath, setFilePath] = useState([]);

  const { addToast } = useToasts();


  const componentConfig = {
    iconFiletypes: ["file"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.FILE.UPLOAD_FILE.path
  };

  const uploadFilesConfig = {
    eventHandlers: {

    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('upload.import-file'),
      dictRemoveFile: props.t('upload.delete-file'),
      dictInvalidFileType: props.t('upload.insupported-file'),
      // addRemoveLinks: true,
      acceptedFiles: ".doc,.docx,.ppt,.pptx",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        // formData.append("file", file);
      }
    }
  };


  useEffect(() => {
    props.template.default_language = getDefaultContentLanguage();
    setModel(props.template)
  }, [props.template]);

  function getDefaultContentLanguage() {
    if (props.template && props.template.template_content) {
      for (let key in props.template.template_content) {
        if (props.template.template_content[key].is_default) {
          return props.template.template_content[key].language;
        }
      }
    }
  };

  const editFile = (file, response, content_template) => {
    console.log("response: ", response);
    if (response && response.data && response.data.file && response.data.file.url) {
      setFilePath(response.data.file.url);
      // props.setFieldValue('file_path', response.data.file.url);
      let request = {
        file_path: response.data.file.url
      }
      TemplatesService.updateContentTemplate(props.template.id, content_template.id, request)
        .then(template => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
    }
  }

  const editTemplate = (field) => {
    return (val) => {
      console.log("typeof here ", typeof val);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        if (field == "enabled") {
          val = (val === true || val === "true") ? true : false;
          request[field] = val;
        } else if (field == "default_language") {
          val = parseInt(val);
          request[field] = val;
        } else {
          request[field] = val;
        }
        model[field] = val;
      }
      setModel({ ...model });
      TemplatesService.update(props.template.id, request)
        .then(template => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
      // updateProduct(request);
    }
  };

  const editContentTemplate = (field, key) => {
    return (val) => {
      console.log("typeof here ", typeof val);
      console.log("key ", key);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      request[field] = val;
      key[field] = val;

      TemplatesService.saveContentTemplate(props.template.id, key.id, request)
        .then(saveContentTemplate => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));

    }
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.view-template-modal-title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(model) && (
        <Tabs initialTab={props.t('globals.referentiel')}>

          <Tab title={props.t('globals.referentiel')}>
            <EditTemplateReferentielComponent template={props.template} />
          </Tab>
          <Tab title={props.t('templates.content-documents')}>
            {(!props.template.template_content || !props.template.template_content.length > 0) && (
              <EmptyContent text={props.t('globals.no-results')} />
            )}
            {(props.template && props.template.template_content && props.template.template_content.length > 0) && (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Col><h5>{props.t('templates.content-par-language')} :</h5></Grid.Col>
                </Grid.Row>
                <Tabs initialTab={((getDefaultContentLanguage() === 0) ? 'FR' : 'EN')}>
                  {props.template.template_content.map((key) => {
                    return (
                      <Tab title={((key.language === 0) ? 'FR' : 'EN')}>
                        <EditTemplateContentComponent template={props.template} template_content={key} />
                      </Tab>
                    )
                  })}
                </Tabs>
              </React.Fragment>

            )}
          </Tab>
        </Tabs>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button type="button" onClick={props.onHide} closeButton
        color="warning">{props.t('modals.close')}</Button>
    </Modal.Footer>

  </Modal>);
};


export default withI18n()(ViewContentTemplateModal);
