// @flow

export const applicationEnabledValues = [{
    value: true,
    key: "yes"
  }, {
    value: false,
    key: "no"
  } 
];


export const applicationServiceProviderValues = [{
    value: 0,
    key: "mandril"
  }, {
    value: 1,
    key: "mailgun"
  } 
];

