// @flow


export const Civility = {
  FEMALE : 0,
  MALE : 1
};

export const OriginApp = {
  CNT : 0,
  M1L : 1
};

export const UserVerificationDocumentStatus = {
  NO_ACTION : 0,
  PENDING : 1,
  REJECTED : 2,
  VALIDATED : 3,
  TREATED : 4,
};

export const AddressCountry = {
  FR : "France",
  DE : "Allemagne",
  AT : "Autriche",
  BE : "Belgique",
  BG : "Bulgarie",
  CY : "Chypre",
  DK : "Danemark",
  ES : "Espagne",
  EE : "Estonie",
  FI : "Finlande",
  GR : "Grèce",
  HR : "Croatie",
  HU : "Hongrie",
  IE : "Irlande",
  IS : "Islande",
  IT : "Italie",
  LV : "Lettonie",
  LI : "Liechtenstein",
  LT : "Lituanie",
  LU : "Luxembourg",
  MT : "Malte",
  NO : "Norvège",
  NL : "Pays-Bas",
  PL : "Pologne",
  PT : "Portugal",
  CZ : "République Tchèque",
  RO : "Roumanie",
  GB : "Royaume-Uni",
  SK : "Slovaquie",
  SI : "Slovénie",
  SE : "Suède",
  CH : "Suisse",
  ZA : "Afrique du sud",
  AU : "Australie",
  CA : "Canada",
  KR : "Corée du sud",
  US : "États-Unis",
  HK : "Hong Kong",
  IN : "Inde",
  JP : "Japon",
  MX : "Mexique",
  SG : "Singapour",
  GP : "Guadeloupe",
  MQ : "Martinique",
  GF : "Guyane",
  YT : "Mayotte",
  NC : "Nouvelle-Calédonie",
  PF : "Polynésie française",
  RE : "La Réunion",
  BL : "Saint-Barthélemy",
  MF : "Saint-Martin",
  PM : "Saint-Pierre-et-Miquelon",
  TF : "Terres australes et antarctiques françaises",
  WS : "Wallis-et-Futuna"
};

export type Address = {
  street? : string,
  city? : string,
  zip_code? : string,
  country? : string
}

export type User = {
  civility? : number,
  first_name : string,
  last_name : string,
  email : string,
  birthdate? : string,
  phone? : string,
  creation_date : Date,
  account_confirmation_date? : Date,
  is_account_confirmed : boolean,
  has_reached_kyc_amount : boolean,
  kyc_verification_date? : Date,
  is_kyc_ok : boolean,
  deletion_date? : Date,
  is_deleted : boolean,
  profile_image_link? : string,
  address? : Address
}