// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Page} from "tabler-react";
import { withI18n } from 'react-i18next';
import {useEffect} from "react";

function HomePage({t}): React.Node {

  useEffect(() => {
    document.title = t('navbar.home')
  }, [t]);


  return (
      <SiteWrapper>
        <Page.Content>
          <Page.Header
              title={t('navbar.home')}/>
        </Page.Content>
      </SiteWrapper>
  );
}


export default withI18n()(HomePage);