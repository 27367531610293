// @flow
import * as React from "react";
import { slide as Menu } from 'react-burger-menu';
import { withI18n } from 'react-i18next';
import { withRouter } from "react-router-dom";
import type { NotificationProps } from "tabler-react";
import { Grid, List, Nav, RouterContextProvider, Site } from "tabler-react";
import { Civility } from "./models/User";
import NavBarService from './services/data/NavBarService';
import AuthService from './services/domain/AuthService';
import KeycloakService from './services/domain/KeycloakService';


// @formatter:off
type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array < NotificationProps >,
|};

// @formatter:on

const getAccountDropdownProps = (t) => {
  const principal = AuthService.getPrincipal();

  if (!principal) {
    return {};
  }

  return {
    avatarURL: `${principal.profile_image_link ? principal.profile_image_link : (principal.civility
      == Civility.FEMALE ? '/assets/images/female.png' : '/assets/images/male.png')}`,
    name: `${principal.given_name} ${principal.family_name}`,
    options: [{ icon: "log-out", value: t("navbar.logout"), onClick: () => KeycloakService.logout() }],
  };
};


class SiteWrapper extends React.Component<Props, State> {
  state = {};

  componentWillMount() {
    // TODO CHANGE ME
    // if (AuthService.userAuthorizations.length === 0) {
    this.setState({ ready: true });
    // } else {
    //   this.setState({ready: true});
    // }
  }

  componentDidMount(): * {
    if (this.state.ready) {
      const navBar = document.querySelector('.nav-tabs');
    }
  }

  render(): React.Node {
    return this.state.ready ? (
      <React.Fragment>
        <Menu>
          <Nav>
            {NavBarService.getNavBarItems(this.props.t).map((item) => {
              return (
                <Nav.Item
                  value={item.value}
                  to={item.to}
                  icon={item.icon}
                  LinkComponent={item.LinkComponent}
                  useExact={item.useExact}
                />
              )
            })}
          </Nav>
        </Menu>

        <Site.Wrapper
          headerProps={{
            href: "/",
            alt: "Emailing",
            imageURL: "/assets/images/logo.png",
            accountDropdown: getAccountDropdownProps(this.props.t)
          }}
          // navProps={{
          //   class: "classes",
          //   className: "classes",
          //   style: 'display: none'
          // }}
          routerContextComponentType={withRouter(RouterContextProvider)}
          footerProps={{
            copyright: (<React.Fragment>
              {this.props.t('footer.copyright')}
            </React.Fragment>), nav: (<React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">

                </List>
              </Grid.Col>
            </React.Fragment>),
          }}
        >
          {this.props.children}
        </Site.Wrapper>
      </React.Fragment >) : '';
  }
}

export default withI18n()(SiteWrapper);
