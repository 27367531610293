// @flow
import Editable from '@mkz-wc/react-editable';
import DropZone from "components/common/dropzone/DropZone";
import React, { useEffect, useState } from 'react';
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import AuthService from "services/domain/AuthService";
import KeycloakService from "services/domain/KeycloakService";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Download, Edit, Eye, EyeOff } from 'tabler-icons-react';
import { Badge, Form, Grid, Icon } from "tabler-react";


const ViewContentTemplateModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropZone, setDropZone] = useState(null);
  const [urlPreview, setUrlPreview] = useState(null);
  const [showEditFile, setShowEditFile] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [filePath, setFilePath] = useState([]);

  const { addToast } = useToasts();

  var myDropZone;

  const componentConfig = {
    iconFiletypes: ["file"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.FILE.UPLOAD_FILE.path
  };

  const djsConfig = {
    maxFilesize: 10,
    maxFiles: 1,
    dictDefaultMessage: props.t('upload.import-file'),
    dictRemoveFile: props.t('upload.delete-file'),
    dictInvalidFileType: props.t('upload.insupported-file'),
    addRemoveLinks: false,
    // acceptedFiles: ".doc,.docx,.ppt,.pptx",
    maxfilesexceeded: function (file) {
      this.removeFile(file);
    },
    sending: function (file, xhr, formData) {
      // formData.append("file", file);
    }
  };


  useEffect(() => {
    props.template.default_language = getDefaultContentLanguage();
    setModel(props.template)
  }, [props.template]);

  useEffect(() => {
    djsConfig.acceptedFiles = "." + props.template_content.storage_service_url.split(".").pop();
  }, [props.template_content]);

  function getDefaultContentLanguage() {
    if (props.template && props.template.template_content) {
      for (let key in props.template.template_content) {
        if (props.template.template_content[key].is_default) {
          return props.template.template_content[key].language;
        }
      }
    }
  };

  const editFile = (file, response) => {
    if (response && response.data && response.data.file && response.data.file.url) {
      setFilePath(response.data.file.url);
      // props.setFieldValue('file_path', response.data.file.url);
      let request = {
        file_path: response.data.file.url
      }
      TemplatesService.updateContentTemplate(props.template.id, props.template_content.id, request)
        .then(template => {
          addToast(props.t('upload.upload-succeed'), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
    }
  }

  const deleteContent = (file, response) => {
    let request = {
      deletion_date: new Date()
    }
    return TemplatesService.updateContentTemplate(props.template.id, props.template_content.id, request)
      .then(templateContent => {
        addToast(props.t(`templates.toast-confirmation-deleted-content-message`), {
          appearance: 'success', autoDismiss: true
        });
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  }

  const handelDownload = () => {
    return TemplatesService.downloadContentTemplate(props.template.id, props.template_content.id)
      .then(response => {

        const file_url = props.template_content.storage_service_url;
        const filename = file_url.split("/").pop();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
  }

  const editContent = (field) => {

    return (val) => {
      console.log("typeof here ", typeof val);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        if (field == "enabled") {
          val = (val === true || val === "true") ? true : false;
          request[field] = val;
        } else if (field == "default_language") {
          val = parseInt(val);
          request[field] = val;
        } else {
          request[field] = val;
        }
        model[field] = val;
      }
      setModel({ ...model });
      return TemplatesService.updateContentTemplate(props.template.id, props.template_content.id, request)
        .then(templateContent => {
          addToast(props.t(`templates.toast-confirmation-deleted-content-message`), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
      // updateProduct(request);
    }

    //   return TemplatesService.updateContentTemplate(props.template.id, props.template_content.id, request)
    //     .then(templateContent => {
    //       addToast(props.t(`templates.toast-confirmation-deleted-content-message`), {
    //         appearance: 'success', autoDismiss: true
    //       });
    //     })
    //     .catch(error => ErrorHandler.handleError(addToast, props.t, error))
    //     .finally(() => setLoading(false));
  }

  return (
    <React.Fragment>
      {(model) && (
        <React.Fragment>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.content-id')}</h5></Grid.Col>
            <Grid.Col>{props.template_content.id}</Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.language')}</h5></Grid.Col>
            <Grid.Col>{((props.template_content.language === 0) ? 'FR' : 'EN')}</Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.is-main-content')}</h5></Grid.Col>
            <Grid.Col>{((props.template_content.is_default) ? props.t('globals.yes') : props.t('globals.no'))}</Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.file-extension')}</h5></Grid.Col>
            {(props.template_content.storage_service_url) && (
              <Grid.Col>{props.template.type}</Grid.Col>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.download')}</h5></Grid.Col>
            <Grid.Col >
              <span class="pull-left">
                <Download title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={handelDownload} />
              </span>
            </Grid.Col>
          </Grid.Row>
          {(props.template_content.tags) && (

            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.tags')}</h5></Grid.Col>
              <Grid.Col >
                {Object.keys(props.template_content.tags).map((tag) => {
                  return (
                    <React.Fragment>
                      {(tag.startsWith("%")) && (
                        <Badge color="success" className="mr-1"><Icon prefix="fa" name="image" /> &nbsp; {tag}</Badge>
                      )}
                      {(tag.startsWith("$")) && (
                        <Badge color="secondary" className="mr-1"><Icon prefix="fa" name="list" /> &nbsp; {tag}</Badge>
                      )}
                      {(!tag.startsWith("%") && !tag.startsWith("$")) && (
                        <Badge color="primary" className="mr-1"><Icon prefix="fa" name="paperclip" /> &nbsp; {tag}</Badge>
                      )}
                    </React.Fragment>
                  )

                })}

              </Grid.Col>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Col><h5>{props.t('globals.enabled')}</h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={props.template_content.enabled}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  onSubmit={editContent('enabled')}
                  isEditable={KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)}
                  type="select"
                  options={[{ label: props.t('globals.yes'), value: true }, { label: props.t('globals.no'), value: false }]}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          {(KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_TEMPLATE)) && (
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.edit')}</h5></Grid.Col>
              <Grid.Col >
                <span class="pull-left">
                  <Edit title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowEditFile(!showEditFile) }} />
                </span>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.preview')}</h5></Grid.Col>
            <Grid.Col >
              <span class="pull-left">
                {(!urlPreview) && (
                  <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={async () => {
                    let _urlPreview = await TemplatesService.getContentTemplateSignedUrl(props.template.id, props.template_content.id);
                    setUrlPreview(_urlPreview);
                  }} />
                )}
                {(urlPreview) && (
                  <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => {
                    setUrlPreview(null);
                  }} />
                )}
              </span>
            </Grid.Col>
          </Grid.Row>

          {(showEditFile) && (
            <DropZone
              componentConfig={componentConfig}
              dropZoneConfig={djsConfig}
              eventHandlers={{
                init: (dropzone) => {
                  console.log("init", dropzone);
                  myDropZone = dropzone;
                  setDropZone(dropzone)
                  // myDropzone = dropzone;
                },
                success: editFile,
                error: (file, error) => {
                  myDropZone.removeFile(file);
                  const errors = {
                    data: {
                      errors: [{
                        code: "UNSUPPORTED_FILE",
                        message: "No Msg"
                      }]
                    }
                  }
                  ErrorHandler.handleError(addToast, props.t, errors)
                }
              }} />
          )}

          {(urlPreview) && (
              <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(urlPreview)}`} style={{ "width": "100%", "height": "250px" }} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};


export default withI18n()(ViewContentTemplateModal);
