import i18n from "i18next";
import {reactI18nextModule} from "react-i18next";

import en from './i18n/en/translations.json';
import fr from './i18n/fr/translations.json';

const resources = {
  en: {
    translations: en
  },
  fr: {
    translations: fr
  }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({

      resources,

      lng: "fr",

      fallbackLng : 'fr',

      defaultNS : "translations",

      fallbackNS: "translations",

      interpolation: {
        escapeValue: false, // react already safes from xss
      }
    });

export default i18n;