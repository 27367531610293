// @flow
import { fileSchema } from '@mkz/editique-validation';
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import _ from "lodash";
import { getDefaultContentLanguage, getLanguageByValue, templateFileTypes } from "models/Template";
import React, { useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import { Button, Form, Grid } from "tabler-react";


const GenerateTestTemplateModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const formRef = useRef();

  function downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const onSubmit = (values, actions) => {
    setLoading(true);
    let body = {
      template_id: props.template.id,
      language: values.language,
      tags: (values.tags) ? JSON.parse(values.tags) : undefined,
      metadata: (values.metadata) ? JSON.parse(values.metadata) : undefined,
      output_type: values.output_type,
      pdf_convertion_options: (values.pdf_convertion_options) ? JSON.parse(values.pdf_convertion_options) : undefined,
    };
    let headers = {
      "client-id": props.application.id,
      "client-secret": props.application.client_secret
    }
    TemplatesService.generateTemplate(body, headers)
      .then(data => {
        let file_type = _.find(templateFileTypes, ['value', values.output_type]);
        downloadBase64File(file_type.content_type, data, `output_${props.template.id}.${file_type.value}`);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form innerRef={formRef} onSubmit={handleSubmit}>
          <Modal.Body>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.id')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    disabled={true}
                    name="template_id"
                    placeholder={props.t('globals.id')} />
                  <InputError name="template_id" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.language')}>
                  <Form.SelectGroup pills >
                    {props.template.template_content.map((content) => {
                      return (
                        <Form.SelectGroupItem
                          label={getLanguageByValue(content.language).label}
                          name="language"
                          value={getLanguageByValue(content.language).label}
                          onChange={(event) => {
                            const value = getLanguageByValue(content.language).label;
                            setFieldValue('language', value);
                          }}
                          checked={(values.language === getLanguageByValue(content.language).label)}
                        />
                      )
                    })}
                  </Form.SelectGroup>
                  <InputError name="language" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.type')}>
                  <Form.SelectGroup pills >
                    <Form.SelectGroupItem
                      label="PDF"
                      name="output_type"
                      value={"PDF"}
                      onChange={(event) => {
                        const value = "PDF";
                        setFieldValue('output_type', value);
                      }}
                      checked={values.output_type == "PDF"}
                    />

                    {(props.template.type.toUpperCase() != "PDF" && props.template.type.toUpperCase() == "DOCX") && (
                      <Form.SelectGroupItem
                        label="DOCX"
                        name="output_type"
                        value={"DOCX"}
                        onChange={(event) => {
                          const value = "DOCX";
                          setFieldValue('output_type', value)
                        }}
                        checked={values.output_type == "DOCX"}
                      />
                    )}
                    {(props.template.type.toUpperCase() != "PDF" && props.template.type.toUpperCase() == "PPTX") && (
                      <Form.SelectGroupItem
                        label="PPTX"
                        name="output_type"
                        value={"PPTX"}
                        onChange={(event) => {
                          const value = "PPTX";
                          setFieldValue('output_type', value)
                        }}
                        checked={values.output_type == "PPTX"}
                      />
                    )}
                  </Form.SelectGroup>
                  <InputError name="output_type" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            {(props.template.type.toUpperCase() != "PDF") && (
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={props.t('templates.tags')}>
                    <Field
                      class="form-control"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      component="textarea"
                      type="text"
                      name="tags"
                      placeholder={props.t('templates.tags')} />
                    <InputError name="tags" />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}

            {(values.output_type == "PDF") && (
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={props.t('templates.metadata')}>
                    <Field
                      class="form-control"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      component="textarea"
                      type="text"
                      name="metadata"
                      placeholder="Metadata" />
                    <InputError name="metadata" />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}

            {(values.output_type == "PDF") && (
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={props.t('templates.pdf_convertion_options')}>
                    <Field
                      class="form-control"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      component="textarea"
                      type="text"
                      name="pdf_convertion_options"
                      placeholder="{pdfFormat: xxxx}" />
                    <InputError name="pdf_convertion_options" />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide}
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" onClick={props.onSubmit}
              color="primary" disabled={loading}
              loading={loading}>{props.t('globals.generate')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.generate-file-modal-title')}</Modal.Title>
    </Modal.Header>
    {(props.template) && (
      <Formik
        onSubmit={onSubmit}
        render={render}
        validationSchema={fileSchema.generate}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          template_id: props.template.id,
          language: getLanguageByValue(getDefaultContentLanguage(props.template).language).label,
          output_type: "PDF",
          tags: `{"title":"Thisisanexample","clients":[{"name":"Doe"}]}`,
          metadata: `{"title":"My custom Title", "subject": "Interesting subject", "producer": "UNTY", "keywords": ["foo", "bar"]}`,
          pdf_convertion_options: `{"pdfFormat":"PDF/A-1a"}`
        }}
      />
    )}
  </Modal>);
};

export default withI18n()(GenerateTestTemplateModal);
