import * as React from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import KeycloakService from 'services/domain/KeycloakService';
import { Error403Page } from "tabler-react";

function ProtectedRoute({ component: Component, authorization, ...rest }) {
  const [isAuthLoaded, setAuthLoaded] = React.useState(false);
  const [isAuthorized, setAuthorized] = React.useState(false);

  useEffect(() => {
    KeycloakService.signinClient().then((data) => {
      if (authorization) {
        if (KeycloakService.hasRole(authorization)) {
          setAuthorized(true);
        }
      } else {
        setAuthorized(true)
      }
      setAuthLoaded(true);
    });
  }, []);

  return (
    <React.Fragment>
      {(isAuthLoaded && isAuthorized) && (
        <Route {...rest} render={(props) => <Component {...props} />} />
      )}
      {(isAuthLoaded && !isAuthorized) && (
        <Error403Page />
      )}
    </React.Fragment>
  );
}

export default ProtectedRoute;
