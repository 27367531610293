import DateService from "@mkz-service/date";
import SearchForm from "@mkz-wc/react-search-form";
import EmptyContent from "components/common/empty-content/EmptyContent";
import Table from "components/common/table/Table";
import $ from "jquery";
import { contentTemplateLanguage } from "models/Template";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { withI18n } from 'react-i18next';
import { useToasts } from "react-toast-notifications";
import ApplicationsService from "services/domain/ApplicationsService";
import { Eye } from 'tabler-icons-react';
import { Badge, Card, Loader } from "tabler-react";
import ViewFileModal from "./file-action/ViewFileModal";

const FileHistoryList = ({ application, t, match }) => {

  const { addToast } = useToasts();

  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewFileModalShowIndex, setViewFileModalShowIndex] = useState(-1);

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState(null);

  const loadData = () => {
    setLoading(true);

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    let search = queryParams.search;
    let page = queryParams.page;
    let limit = queryParams.limit;

    ApplicationsService.getFileHistory(application.id, { search, page, limit })
      .then(data => {
        setList(data.list);
        setPagination(data.pagination)
      })
      .finally(() => setLoading(false));
  };

  const paginationOnLoadingStart = () => {
    setLoading(true);
  };

  const paginationOnLoadingEnd = (data) => {
    setList(data.list);
    setPagination(data.pagination)
    setLoading(false);

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams.page = data.pagination.currentPage;
    queryParams.limit = data.pagination.limit;

    window.history.pushState({ path: window.location.pathname }, '', `${window.location.pathname}?${qs.stringify(queryParams)}#history`)
  };

  const handelSearch = (search) => {
    loadData()
  };

  useEffect(() => {
    window.history.pushState(null, null, "#history");
    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
    loadData()
    // EVENT LISTNET RESET QUERY PARAMS
    var elements = $(".Tab_header_tabs .nav-item");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', () => {
        resetQueryParams();
      }, false);
    }
  }, []);

  const resetQueryParams = () => {

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams.page = undefined;
    queryParams.limit = undefined;
    queryParams.search = undefined;

    window.history.pushState({ path: window.location.pathname }, '', `${window.location.pathname}?${qs.stringify(queryParams)}#referentiel`)

  }

  const headerItems = [
    { content: "Nom Template" },
    { content: "Language" },
    { content: "File Type" },
    { content: "Output Type" },
    { content: "Date d'envoie" },
    { content: "Statut" },
    { content: "Actions" }];

  const itemRow = (item, index) => {
    return {
      key: item.id, item: [
        { content: (<span>{item.tp_name}</span>) },
        {
          content: (<span>
            <span>
              {contentTemplateLanguage.map((language) => {
                if (item.tc_language === language.value) {
                  return (
                    <img className="language-template" src={language.logo_src} />
                  )
                }
              })}
            </span>
          </span>)
        },
        { content: (<span>{item.tp_type}</span>) },
        { content: (<span>{item.fth_output_type}</span>) },
        { content: (<span>{DateService.formatTime(item.fth_creation_date)}</span>) },
        {
          content: (<span>
            {(item.fth_status == 1) && (
              <Badge color="success" className="mr-1">{t('files.status-success')}</Badge>
            )}
            {(item.fth_status == 0) && (
              <Badge color="danger" className="mr-1">{t('files.status-fail')}</Badge>
            )}
          </span>)
        },
        {
          content: (
            <span>
              <Eye className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setViewFileModalShowIndex(index) }} />
              <ViewFileModal
                file={item}
                show={viewFileModalShowIndex === index}
                onHide={() => setViewFileModalShowIndex(-1)} />
            </span>)
        },
      ]
    };
  };

  return (<React.Fragment>

    <Card>
      <Card.Header>
        <Card.Title>{t('templates.templating-history')}</Card.Title>
        <Card.Options>
          <SearchForm
            search={search}
            manageQueryParam={true}
            placeholder={t('files.search-placeholder')}
            onSubmit={handelSearch}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>

        {((!list || !list.length) && !loading) ? (
          <EmptyContent text={t('globals.no-results')} />) : ''}

        {((list && list.length) && !loading) ? (
          <Table responsive
            className="card-table"
            headerItems={headerItems}
            bodyItems={list.map(itemRow)}
            pagination={pagination}
            onLoadingStart={paginationOnLoadingStart}
            onLoadingEnd={paginationOnLoadingEnd}
          />
        ) : ''}

        {loading && <Loader />}

      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(FileHistoryList);
