/**
 * LinksProvider
 * @module Services/http/LinksProvider
 * @description Offers methods to handle routes and API endpoints
 */
import { METHODS } from '@mkz-service/http';
import { formatFullUrl, formatUrl } from '@mkz-service/http/dist/services/UrlFormatterService';

const self = {};

self.ROUTES = {
  LOGIN: '/login',

  HOME: '/',

  APPLICATIONS: '/applications',
  APPLICATION: '/applications/:id',


  TEMPLATE: '/template/:template_id/content/:content_id',

  USERS: '/users',
  USER: '/users/:id',
};

self.API = {
  AUTH: {
    AUTH_TOKEN: { method: METHODS.POST, path: '/auth/signin' }
  },
  APPLICATION: {
    CREATE_APPLICATION: { method: METHODS.POST, path: '/admin/application' },
    IMPORT_APPLICATION: { method: METHODS.POST, path: '/admin/application/import' },
    GET_APPLICATIONS: { method: METHODS.GET, path: '/admin/application' },
    GET_MY_APPLICATIONS: { method: METHODS.GET, path: '/pro/application' },
    GET_APPLICATION_DETAILS: { method: METHODS.GET, path: '/pro/application/:id' },
    UPDATE_MY_APPLICATION: { method: METHODS.PUT, path: '/pro/application/:id' },
    EXPORT_MY_APPLICATION: { method: METHODS.GET, path: '/pro/application/:id/export' },
    IMPORT_MY_APPLICATION: { method: METHODS.POST, path: '/pro/application/:id/import' },
    FILE_HISTORY: { method: METHODS.GET, path: '/pro/application/:id/file/history' },
  },
  TEMPLATE: {
    GET_APP_TEMPLATES: { method: METHODS.GET, path: '/pro/application/:id/template' },
    CREATE_TEMPLATE: { method: METHODS.POST, path: '/pro/application/:applicationId/template' },
    UPDATE_TEMPLATE: { method: METHODS.PUT, path: '/pro/template/:id' },
    CREATE_CONTENT_TEMPLATE: { method: METHODS.POST, path: '/pro/template/:id/content' },
    GET_CONTENT_TEMPLATE: { method: METHODS.GET, path: '/pro/template/:template_id/content/:content_id' },
    SAVE_CONTENT_TEMPLATE: { method: METHODS.PUT, path: '/pro/template/:template_id/content/:content_id' },

    UPDATE_CONTENT_TEMPLATE: { method: METHODS.PUT, path: '/pro/template/:template_id/content/:content_id' },
    EXPORT_TEMPLATE: { method: METHODS.GET, path: '/pro/template/:id/export' },
    DOWNLOAD_CONTENT_TEMPLATE: { method: METHODS.GET, path: '/pro/template/:template_id/content/:content_id/download' },
    GET_CONTENT_TEMPLATE_SIGNED_URL: { method: METHODS.GET, path: '/pro/template/:template_id/content/:content_id/signed-url' },

  },
  USER: {
    GET_USERS_SCOPE: { method: METHODS.GET, path: '/admin/user' },
    UPDATE_USER_APPLICATION_SCOPE: { method: METHODS.PUT, path: '/admin/user/:id/application/scope' },
  },
  FILE: {
    UPLOAD_FILE: { method: METHODS.POST, path: '/file/upload' },
    GENERATE_FILE: { method: METHODS.POST, path: '/app/file/generate' }
  },
};


self.formatUrl = formatUrl;

self.formatFullUrl = formatFullUrl;

export default self;
