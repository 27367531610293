// @flow
import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Button} from "tabler-react";
import {withI18n} from 'react-i18next';

function ConfirmModal(props): React.Node {
  const [loading, setLoading] = useState(false);
  const confirm = () => {
    setLoading(true);
    props.onConfirm()
        .then(() => {

        })
        .finally(() => setLoading(false));
  };

  return (
      <Modal {...props} size="md">
        <Modal.Body>
            {props.confirmationText}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} color="warning" closeButton>{props.t('modals.no')}</Button>
          <Button loading={loading} onClick={confirm} color="primary">{props.t('modals.yes')}</Button>
        </Modal.Footer>
      </Modal>
  );
}

export default withI18n()(ConfirmModal);