// @flow
import * as React from "react";
import { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { Grid, Loader, Page, Tab, Tabs } from "tabler-react";
import ApplicationsService from "../../../services/domain/ApplicationsService";
import SiteWrapper from "../../../SiteWrapper";
import ApplicationDetails from "./components/application-details/ApplicationDetails";
import FileHistoryList from "./components/file-history/FileHistoryList";

function ApplicationDetailsPage({ t, match }): React.Node {

    const [application, setApplication] = useState(null);
    const [loadingCode, setLoadingCode] = useState(false);
    const [currentTab, setCurrentTab] = useState(null);
    // const [creationModalShown, setCreationModalShown] = useState(false);

    const handelInitialTab = () => {
        console.log("window.location", window.location.hash);
        let tab = (window.location.hash) ? window.location.hash.slice(1, window.location.hash.length) : "referentiel"

        if (tab === "history") {
            setCurrentTab(t('applications.application-page-tab-title-history'))
        } else {
            setCurrentTab(t('applications.application-page-tab-title-referentiel'))
        }
    }

    useEffect(() => {
        document.title = `${t('navbar.application')} : ${match.params.id}`;
        handelInitialTab();
        loadData();
    }, [t, match.params.id]);

    const loadData = () => {
        setLoadingCode(true);

        return ApplicationsService.getApplication(match.params.id)
            .then(application => setApplication(application))
            .finally(() => setLoadingCode(false))

    };

    return (
        <SiteWrapper>
            <Page.Content>
                {(application) && (
                    <Page.Header
                        title={`${t('navbar.application')} : ${application.name}`}
                        options={(
                            <Grid.Row>
                                <Grid.Col>
                                </Grid.Col>
                            </Grid.Row>)} />
                )}
                <Page.Main>
                    {(currentTab) && (
                        <Tabs initialTab={currentTab}>
                            <Tab class="test" title={t('applications.application-page-tab-title-referentiel')} >
                                {loadingCode && <Loader />}

                                {(!loadingCode && application) && (
                                    <ApplicationDetails application={application} />
                                )}
                            </Tab>
                            <Tab class="test" title={t('applications.application-page-tab-title-history')} >
                                {loadingCode && <Loader />}

                                {(!loadingCode && application) && (
                                    <FileHistoryList application={application} />
                                )}

                            </Tab>
                        </Tabs>
                    )}
                </Page.Main>
            </Page.Content>
        </SiteWrapper>
    );
}

export default withI18n()(ApplicationDetailsPage);
