// @flow
import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Button, Form, Grid} from "tabler-react";
import {Formik} from "formik";
import {withI18n} from "react-i18next";
import InputError from "components/common/input-error/InputError";
import {useToasts} from "react-toast-notifications";
import TemplatesService from 'services/domain/TemplatesService';


const ModificationModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const onSubmit = (values, actions) => {
    props.contentTemplate.title = values.title;
    props.contentTemplate.description = values.description;
    const contentTemplateBody = {
      title : values.title,
      description : values.description
    };
    TemplatesService.saveContentTemplate(props.template_id, props.content_id, contentTemplateBody)
      .then(saveContentTemplate => {
      })
    props.onHide && props.onHide();
    props.afterHide && props.afterHide(props.contentTemplate);
  };

  const render = ({handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors}) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.title')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="title"
                    value={values.title}
                    placeholder={props.t('templates.title')}/>
                  <InputError name="title"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.description')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="description"
                    value={values.description}
                    placeholder={props.t('templates.description')}/>
                  <InputError name="description"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
                    color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
                    loading={loading}>{props.t('globals.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.modify-article-title-modal-title')}</Modal.Title>
    </Modal.Header>
    {(props.contentTemplate) && (

      <Formik
      onSubmit={onSubmit}
      render={render}
      // validationSchema={ticketSchema.create}
      initialValues={{
        title: props.contentTemplate.title,
        description: props.contentTemplate.description
      }}
      />
      )}
  </Modal>);
};

export default withI18n()(ModificationModal);
