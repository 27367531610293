/**
 * UsersService
 * @module Services/domain/UsersService
 * @description Loads users data from the server
 */

import { User } from "../../models/User";
import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";
// import {TARGET_APP} from "models/RequestInformation";

const self = {};

/**
 * Searches for a user with a specific query
 * @author Sameh Bellez
 * @alias search
 * @memberof module:Services/http/UsersService
 * @param   {string} query The search query
 * @returns {Promise<User>}  A promise containing the search result
 */
self.search = (query: string): Promise<User> => {
  return HttpBackendClient.method(LinksProvider.API.USERS.USER, { search: query })
    .then(data => data.user);
};

/**
 * Updates user information
 * @author Sameh Bellez
 * @alias update
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @param   {any} data The information to update
 * @returns {Promise<User>}  A promise containing the updated user
 */
self.update = (email: string, data: any): Promise<User> => {
  return HttpBackendClient.method(LinksProvider.API.USERS.USER, { search: email }, data)
    .then(data => data.user);
};


self.getMyApplications = (): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.GET_MY_APPLICATIONS)
    .then(data => data);
};

self.getUsersScope = (): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.USER.GET_USERS_SCOPE)
    .then(data => data);
};

self.updateUsersScope = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.USER.UPDATE_USER_APPLICATION_SCOPE, { id }, data)
    .then(data => data);
};

export default self;
