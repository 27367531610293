// @flow
import DateService from "@mkz-service/date";
import { getLanguageByValue } from "models/Template";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import JSONPretty from 'react-json-prettify';
import { github } from 'react-json-prettify/dist/themes';
import { useToasts } from "react-toast-notifications";
import { Eye, EyeOff } from 'tabler-icons-react';
import { Badge, Button, Grid, Tab, Tabs } from "tabler-react";

const ViewFileModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showRequestContent, setShowRequestContent] = useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    setModel(props.file)
  }, [props.file]);

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('files.view-templating-details-modal')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(model) && (
        <Tabs initialTab={props.t('files.file')}>
          <Tab title={props.t('files.file')}>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.id')}</h5></Grid.Col>
              <Grid.Col>{model.fth_id}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.creation-date')}</h5></Grid.Col>
              <Grid.Col>{DateService.formatTime(model.fth_creation_date)}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.status')}</h5></Grid.Col>
              <Grid.Col>
                {(model.fth_status == 1) && (
                  <Badge color="success" className="mr-1">{props.t('files.status-success')}</Badge>
                )}
                {(model.fth_status == 0) && (
                  <Badge color="danger" className="mr-1">{props.t('files.status-fail')}</Badge>
                )}
              </Grid.Col>
            </Grid.Row>

            {(model.fth_status == 0) && (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Col><h5>{props.t('files.error-message')}</h5></Grid.Col>
                  <Grid.Col>
                    {model.fth_error_message}
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col><h5>{props.t('files.error-stack')}</h5></Grid.Col>
                  <Grid.Col>
                    {model.fth_execution_stack}
                  </Grid.Col>
                </Grid.Row>
              </React.Fragment>
            )}
            <Grid.Row>
              <Grid.Col><h5>{props.t('files.request-content')}</h5></Grid.Col>
              <Grid.Col >
                <span class="pull-left">
                  {(showRequestContent === false) && (
                    <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowRequestContent(true) }} />
                  )}
                  {(showRequestContent === true) && (
                    <React.Fragment>
                      <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowRequestContent(false) }} />
                      <br />
                      <span>
                        <JSONPretty json={model.fth_request_content} theme={github} padding={4} />
                      </span>
                    </React.Fragment>
                  )}
                </span>
              </Grid.Col>
            </Grid.Row>
          </Tab>

          <Tab title={props.t('templates.template')}>
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.id')}</h5></Grid.Col>
              <Grid.Col>{model.tp_id}</Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.name')}</h5></Grid.Col>
              <Grid.Col>{model.tp_name}</Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.description')}</h5></Grid.Col>
              <Grid.Col>{model.tp_description}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.content-id')}</h5></Grid.Col>
              <Grid.Col>{model.tc_id}</Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.language')}</h5></Grid.Col>
              <Grid.Col>{getLanguageByValue(model.tc_language).label}</Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.preview')}</h5></Grid.Col>
              <Grid.Col >
                <span class="pull-left">
                  {(showPreview === false) && (
                    <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(true) }} />
                  )}
                  {(showPreview === true) && (
                    <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(false) }} />
                  )}
                </span>
              </Grid.Col>
            </Grid.Row>

            {(showPreview) && (
              <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${model.tc_storage_service_url}&embedded=true}`} style={{ "width": "100%", "height": "250px" }} />
              // <iframe src={`https://docs.google.com/gview?url=${model.tc_storage_service_url}&embedded=true`} style={{ "width": "100%", "height": "250px" }} />
            )}
          </Tab>
        </Tabs>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button type="button" onClick={props.onHide} closeButton
        color="warning">{props.t('modals.close')}</Button>
      {/* <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('modals.confirm')}</Button> */}
    </Modal.Footer>

  </Modal>);
};


export default withI18n()(ViewFileModal);
