// @flow
import { templateSchema } from '@mkz/editique-validation';
import DropZone from "components/common/dropzone/DropZone";
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import { contentTemplateLanguage } from "models/Template";
import React, { useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Button, Form, Grid, Icon } from "tabler-react";


const CreationModal = (props): React.Node => {

  const [filePath, setFilePath] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const formRef = useRef();

  const componentConfig = {
    iconFiletypes: ["file"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.FILE.UPLOAD_FILE.path
  };

  const uploadFilesConfig = {
    eventHandlers: {

    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('upload.import-file'),
      dictRemoveFile: props.t('upload.delete-file'),
      dictInvalidFileType: props.t('upload.insupported-file'),
      addRemoveLinks: true,
      acceptedFiles: ".docx,.pptx,.pdf",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        // formData.append("file", file);
      }
    }
  };

  const onSubmit = (values, actions) => {
    setLoading(true);
    let body = {
      application_id: props.application.id,
      name: values.name,
      description: values.description,
      language: values.language,
      file_path: filePath
    };
    TemplatesService.createTemplate(props.application.id, body)
      .then(template => {
        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(template);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form innerRef={formRef} onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.name')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="name"
                    placeholder={props.t('globals.name')} />
                  <InputError name="name" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row><Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.description')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    component="textarea"
                    type="text"
                    name="description"
                    placeholder={props.t('globals.description')} />
                  <InputError name="description" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.language')}>
                  <Form.SelectGroup pills >
                    {contentTemplateLanguage.map((language) => {
                      return (
                        <Form.SelectGroupItem
                          label={language.label}
                          name="language"
                          value={language.label}
                          onChange={(event) => {
                            const value = language.label;
                            console.log("**************** vallue", value);
                            setFieldValue('language', value);
                          }}
                          checked={values.language === language.label}
                        />
                      )
                    })}
                  </Form.SelectGroup>
                  <InputError name="language" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xs="12" sm="12">
                <Form.Group label={props.t('upload.file')}>
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={uploadFilesConfig.djsConfig}
                    eventHandlers={{
                      success: (file, response) => {
                        console.log("response: ", response);
                        if (response && response.data && response.data.file && response.data.file.url) {
                          setFilePath(response.data.file.url);
                          setFieldValue('file_path', response.data.file.url);
                        }
                      },
                      error: (file, error) => {
                        ErrorHandler.handleError(addToast, props.t, error)
                      }
                    }} />

                  <InputError name="file_path" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <span>
              <Icon prefix="fe" name="alert-circle text-primary" />
              &nbsp;Veuillez vous référer à la documentation de &nbsp;
              <a target="_blank" href="https://docxtemplater.com/demo/#simple">
                docxtemplater sur le lien suivant
              </a>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide}
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.upload')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.create-template-title')}</Modal.Title>
    </Modal.Header>
    {(props.application) && (
      <Formik
        onSubmit={onSubmit}
        render={render}
        validationSchema={templateSchema.create}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          name: "",
          description: "",
          language: "",
          file_path: "",
          application_id: props.application.id,
        }}
      />
    )}
  </Modal>);
};

export default withI18n()(CreationModal);
